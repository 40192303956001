<template>
  <div class="complete">
    <component :is="template"/>
    <span v-html="affiliate"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CwComplete',

  components: {
    'cw-continuous-loan-complete': () => import('@se/pages/steps/CompleteContinuousLoan'),
    'cw-instalment-loan-complete': () => import('@se/pages/steps/CompleteInstalmentLoan'),
  },

  data: () => ({
    template: '',
    affiliate: null,
  }),

  computed: {
    ...mapGetters({
      offer: 'application/getSelectedOffer',
    }),
  },

  async created() {
    const { type } = this.offer;

    this.setTemplate(type);
    this.affiliate = await this.getAdTracking();

    /**
     * Set the purchase property to false to prevent duplicate purchase events in GA
     */
    this.setPurchase(false);
  },

  methods: {
    ...mapActions({
      getAdTracking: 'application/getAdTracking',
      setPurchase: 'application/setPurchase',
    }),

    setTemplate(type) {
      if (!type) return;

      const offerTypes = {
        continuousLoan: 'cw-continuous-loan-complete',
        fixedPeriodContinuousLoan: 'cw-continuous-loan-complete',
        instalmentLoan: 'cw-instalment-loan-complete',
        carInstalmentLoan: 'cw-car-loan-complete',
      };

      this.template = offerTypes[type];
    },
  },
};
</script>
